<template>
  <div class="form-section-naga">
    <div class="container">
      <div class="form-section-naga-content">
        <div class="form-section-naga-content-left">
          <div class="form-section-naga-content-left-header">
            <h3>
              It’s your time to get to learn a new skill! Forex trading from the
              comfort of your own home.
            </h3>
          </div>

          <div class="form-section-naga-content-left-content">
            <div class="form-section-naga-content-left-content-left-side">
              <div class="bulet-points">
                <p>01</p>
                <p>Register for FREE</p>
              </div>
              <div class="bulet-points">
                <p>02</p>
                <p>
                  Take advantage of our exclusive eBook and personal 1-on-1
                  training to get you started
                </p>
              </div>
              <div class="bulet-points">
                <p>03</p>
                <p>
                  Learn how with the power of leverage you can start trading
                  with really low amounts.
                </p>
              </div>
            </div>

            <div class="form-section-naga-content-left-content-img-right-side">
              <img
                src="../../../assets/lps/naga/form-section-blue.svg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="form-section-naga-spacer">
          <img src="../../../assets/lps/naga/form-section-spacer.svg" alt="" />
        </div>

        <div class="form-section-naga-content-right">
          <div class="form-section-naga-content-right-content">
            <div class="form-section-naga-content-right-header">
              <!-- <h3>Register your <span>free</span> account</h3> -->
            </div>
            <div class="form-section-naga-content-right-p">
              <!-- <p>Make moves in the financial market with our exclusive eBook and personal training package</p> -->
            </div>

            <div class="iframe-container">
              <iframe
                v-show="iframeIsReady"
                v-resize="{
                  inPageLinks: true,
                  heightCalculationMethod: 'grow',
                }"
                width="100%"
                height="100%"
                src="https://blog.leaninvestor.io/forms/external-serious-leads-quiz-filter?broker_name=naga"
                frameborder="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return { iframeIsReady: false };
  },

  methods: {},

  mounted() {
    window.addEventListener("mousemove", () => {
      this.iframeIsReady = true;
    });

    window.addEventListener("touchmove", () => {
      this.iframeIsReady = true;
    });
  },
};
</script>

<style>
.form-section-naga-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-section-naga-content-left {
  max-width: 674px;
  width: 100%;
  padding-top: 50px;
}

.form-section-naga-content-left-header h3 {
  line-height: 46px;
  font-size: 38px;
}

.form-section-naga-content-left-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
}

.bulet-points p:nth-child(1) {
  color: #3179ff;
  margin-right: 15px;
}

.bulet-points {
  display: flex;
  max-width: 250px;
  width: 100%;
  margin-bottom: 20px;
}
.bulet-points p {
  font-size: 21px;
}

.form-section-naga-spacer {
  margin-top: 130px;
}

.form-section-naga-content-right {
  height: auto;
}

.iframe-container iframe {
  width: 100%;
  height: 270px;
}

.form-section-naga-content-right-header h3 {
  text-align: center;
  font-size: 38px;
  padding-bottom: 18px;
}
.form-section-naga-content-right-header h3 span {
  color: #3179ff;
}

.form-section-naga-content-right-p p {
  text-align: center;
  font-size: 21px;
  max-width: 606px;
  width: 100%;
  padding-bottom: 10px;
}

.form-section-naga-content-right {
  max-width: 606px;
  width: 100%;
}

.form-section-naga-content-right {
  margin-top: 50px;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.1);
}

.form input {
  height: 55px !important;
  margin-bottom: 10px;
}

.submit-button-group {
  text-align: center;
}

.register-btn {
  background: #3179ff !important;
  color: #fff !important;
  font-size: 21px !important;
  height: 65px !important;
  max-width: 320px !important;
  width: 100%;
}

@media (max-width: 1400px) {
  .form-section-naga-spacer {
    display: none;
  }
  .form-section-naga-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .form-section-naga-content-left-header h3 {
    text-align: center;
    font-size: 26px;
    line-height: 32px;
    max-width: 530px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .form-section-naga-content-left {
    padding-top: 45px;
  }

  .form-section-naga-content-right-header h3 {
    font-size: 26px;
    padding-bottom: 18px;
  }

  .form-section-naga-content-right-p p {
    font-size: 18px;
  }

  .form-section-naga-content-left {
    max-width: 900px;
  }

  .bulet-points {
    display: block;
    max-width: 350px;
  }
}

@media (max-width: 768px) {
  .bulet-points {
    display: block;
    max-width: 220px;
  }

  .form-section-naga-content-left-content-img-right-side img {
    width: 343px !important;
  }
}

@media (max-width: 650px) {
  .form-section-naga-content-left-content {
    flex-direction: column;
    text-align: center;
  }

  .form-section-naga-content-left-content-img-right-side img {
    width: 230px !important;
    margin-top: 18px;
  }

  .bulet-points {
    margin-bottom: 30px;
  }
  .bulet-points p:nth-child(1) {
    margin-bottom: 7px;
  }
  .bulet-points p {
    font-size: 18px;
  }

  .form-section-naga-content-left-content {
    padding-top: 5px;
  }

  .form-section-naga-content-right {
    margin-top: 50px;
  }
}
</style>